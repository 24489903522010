<template>
  <v-card rounded="lg" flat max-width="500" class="pa-md-12 pa-4 pb-md-5 width100" min-height="448">
    <div @click="toLogin" class="link primary--text text--lighten-1 mb-7">
      <v-icon color="primary lighten-1">mdi-chevron-left</v-icon> Back to Log In
    </div>
    <div v-if="card == 'recovery'">
      <div class="f32 mb-1">Password recovering</div>
      <div class="mb-8 text--text">
        Please enter the email address you have used to sign in. You will receive the email with the password reset instructions.
        Additionally, you can contact the platform administrator.
      </div>
      <v-form @submit.prevent="action" ref="form" lazy-validation>
        <v-text-field
          class="field46"
          v-model="email"
          placeholder="Email"
          :error-messages="emailErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-btn depressed type="submit" block large color="primary">Confirm</v-btn>
      </v-form>
    </div>
    <div v-if="card == 'done'">
      <div class="f32 mb-2">Password reset instructions sent</div>
      <div class="text--text">Please check your email for the password reset instructions.</div>
      <v-img class="mt-8" contain height="150" src="@/assets/img/wait.svg"></v-img>
    </div>
    <div v-if="card == 'error'">
      <div class="f32 mb-2">Reset attempt limit</div>
      <div class="text--text">You used all available attempts to reset your password. Please try again later.</div>
      <v-img class="mt-8" contain height="150" src="@/assets/img/bad.svg"></v-img>
    </div>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      card: 'recovery',
      email: '',
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'cred') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'We couldn’t find an account matching the email you entered.',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'notFound') {
        this.$notify({
          title: 'Account not found',
          message: 'Check if the provided email address is correсt.',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    toLogin() {
      this.$router.push('/login');
    },
    async action() {
      this.error = [];
      const data = new Object();
      data.email = this.email;
      await this.$store
        .dispatch('reqResetPassword', data)
        .then(() => {
          this.card = 'done';
        })
        .catch((e) => {
          this.loginError(e);
        });
    },
    loginError(e) {
      this.error = e.response.data.error;
      if (this.error.find((item) => item == 'credential__invalid')) {
        this.notifi('cred');
      } else if (this.error.find((item) => item == 'staff__suspended')) {
        this.$router.push('/blocked');
      } else if (this.error.find((item) => item == 'staff__not_found')) {
        this.notifi('notFound');
      } else if (this.error.find((item) => item == 'password_reset__limit')) {
        this.card = 'error';
      }
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email is not valid');
      this.error.find((item) => item == 'email__not_confirmed') && errors.push('Provided email is not confirmed');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>
